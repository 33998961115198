#room{
    width: 90%;
    margin: 0 auto;
    text-align: left;
}

#room ul{
    padding-left: 20px;
    font-size: 14px;
    list-style-type: square;
}

#roomType {
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
    position: relative;
    cursor: pointer;
}

#roomType h2 {
    position: absolute;
    top: 0;
    width:100%;
    text-align: left;
    padding: 10px 0 10px 10px;
    background-color: #eaeef3;
    font-size: 17px;
    color: #2d5e90;
}

#roomType i {
    position: absolute;
    top: 0;
    right: 0;
    display:block;
    padding: 7.5px 5px 5px 0;
    color: #2d5e90;
}

#roomType img{
    width: 100%;
    height: auto;
}

#roomType .booking-button{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 32px;
    display:block;
    text-decoration: none;
    border-radius: 5px 0 0 0;
    line-height: 100%;
    width: initial;
    margin: 0;
}

#roomType .booking-button p{
    margin: 0;
    padding: 0;
    color: white;
    font-size: 14px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    line-height: 100%;
}

#roomType .room-book:hover {
    background-color: #3c5976;
    text-decoration: none;
    color: white;
}

/* #roomType #overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */

/* Modal */
.room-modal {
  display: none; 
  position: fixed;
  z-index: 1; 
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); 
}

.room-modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  margin-top: 35px;
  padding: 0;
  width: 95%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.room-modal-content .flex{
    display:flex;
    text-align: left;
    justify-content: center;
}

.room-modal-content h1, .room-modal-content h2, .room-modal-content p {
    padding-left: 10px;
}

.room-modal-content h1{
    color: #2d5e90;
}
.room-modal-content h2{
    font-size: 18px;
}

.room-modal-content p {
    font-size: 14px;
}

.modal-book-button {
    width: 50%;
    background-color: #2d5e90;
    padding: 10px 32px;
    margin: 0 auto;
    margin-bottom: 10px;
    display:block;
    text-align: center;
}

.modal-book-button:hover {
    background-color: #3c5976;
    text-decoration: none;
    color: white;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}
  
@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
  
.room-modal-content .closeModal {
    color: #3c5976;
    font-size: 40px;
    font-weight: bold;
    float:right;
    padding: 0 10px;
    cursor: pointer;
}
  
.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (min-width: 800px) {
    #room {
        width: 80%;
        max-width: 850px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }

    #room h1, #room p {
        width: 100%;
    }

    #roomType {
        width: 48%;
        margin: 0 1%;
        margin-bottom: 10px;
    }

    .extraInfo {
        width: 100%;
        display: flex;
        margin: 10px 0;
        padding: 10px 0;
        border-top: 2px solid #2d5e90;
        border-bottom: 2px solid #2d5e90;
    }
    .infoList {
        width: 50%;
    }

    .room-modal-content {
        width: 80%;
        max-width: 400px;
        max-height: 90vh;
        overflow: auto;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}


