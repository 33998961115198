#carouselHome {
    max-height: 100%;
    height: 100%;
}

.carousel-inner {
    height:100%;
}

.carousel-item {
    height:100%;
}

.bg {
    height:100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

/*heading for carousel item */
.carousel-item p {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    color: #2d5e90;
    padding: 5px 10px;
    display: inline-block;
    width: fit-content !important;
    font-weight: bold;
    border-bottom-right-radius: 5px;
}