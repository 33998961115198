#contact {
    padding: 0 15px;
    text-align: left;
}

#contact h2 {
    padding-top: 10px;
    font-size: 20px;
    color: #2d5e90;
    margin: 0;
}

#contactInfo {
    padding: 10px;
    background-color:#2d5e90;
}

#contact img{
    width:100%;
    height:auto;
}

#contactInfo, #contactInfo i{
    font-size: 14px;
}

#contactInfo a {
    margin: 0;
    padding: 0;
    padding-left: 5px;
    display: block;
    text-decoration: none;
    color: inherit;
    color: white;
}

#contactInfo a:hover {
    text-decoration: underline;
}

#contactInfo img {
    width: 100%;
    height: auto;
}

form {
    margin: 0 0 2em 0;
    overflow-x: hidden;
}

    form > :last-child {
        margin-bottom: 0;
    }

    form > .fields {
        display: -moz-flex;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: calc(100% + 3em);
        margin: -1.5em 0 10px -1.5em;
    }

        form > .fields > .field {
            -moz-flex-grow: 0;
            -webkit-flex-grow: 0;
            -ms-flex-grow: 0;
            flex-grow: 0;
            -moz-flex-shrink: 0;
            -webkit-flex-shrink: 0;
            -ms-flex-shrink: 0;
            flex-shrink: 0;
            padding: 1.5em 0 0 1.5em;
            width: calc(100% - 1.5em);
        }

            form > .fields > .field.half {
                width: calc(50% - 0.75em);
            }

            form > .fields > .field.third {
                width: calc(100%/3 - 0.5em);
            }

            form > .fields > .field.quarter {
                width: calc(25% - 0.375em);
            }

    @media screen and (max-width: 480px) {

        form > .fields {
            width: calc(100% + 3em);
        }

            form > .fields > .field {
                padding: 1.5em 0 0 1.5em;
                width: calc(100% - 1.5em);
            }

                form > .fields > .field.half {
                    width: calc(100% - 1.5em);
                }

                form > .fields > .field.third {
                    width: calc(100% - 1.5em);
                }

                form > .fields > .field.quarter {
                    width: calc(100% - 1.5em);
                }

    }


input[type="text"],
input[type="email"],
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #c9c9c9;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0;
    text-decoration: none;
    width: 100%;
}

    input[type="text"]:invalid,
    input[type="email"]:invalid,
    textarea:invalid {
        box-shadow: none;
    }

    input[type="text"]:focus,
    input[type="email"]:focus,
    textarea:focus {
        border-bottom-color: #c0cedd;
        box-shadow: inset 0 -1px 0 0 #c0cedd;
    }

input[type="text"],
input[type="password"],
input[type="email"],
select {
    height: 3em;
}

textarea {
    padding: 0;
    min-height: 3.75em;
}


#contactForm {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 10px;
}

#contactForm button {
  background-color: #2d5e90;
  border: none;
  color: white;
  padding: 15px 32px;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;  
  cursor: pointer;
  width: 50%;
  margin: 0 auto;
  display:block;
  border-radius: 5px;
}

#contactForm button:hover {
    background-color: #3977b7;
}

.hidden-img {
    display:none;
}

@media only screen and (min-width: 600px) {
    #contact {
        max-width: 850px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    #contact h1{
        width:100%;
    }

    #contact img {
        width: 50%;
        height: 50%;
    }

    #contactInfo {
        width:50%;
        display:flex;
        flex-wrap: wrap;
        align-items: center;
    }

    #contactInfo a {
        width: 100%;
    }

    #contactForm{
        width: 50%;
        padding: 10px 10px 10px 0;
        
    }

    .hidden-img {
        display:block;
    }
}
