@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,700');

h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #262626;
  font-weight: 700;
}

h3 {
  font-weight: 300;
}
p {
  color: #262626;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.booking-button {
  width: 50%;
  max-width: 300px;
  background-color: #2d5e90;
  color: white;
  padding: 10px 32px;
  margin: 0 auto;
  margin-bottom: 10px;
  display:block;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 700;
}

.booking-button:hover {
  background-color: #3977b7;
  text-decoration: none;
  color: white;
}

.underline {
  position: relative;
  font-weight: 300;
}

.underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #2d5e90;
}

