footer {
    display: flex;
    flex-wrap: wrap;
    background-color: #2d5e90;
    color: #fff;
    width:100%;
    bottom: 0;
    left: 0;
    position: absolute;
    margin-top: 45px;
}

.footerinfo {
    width: 80%;
    margin: 0 auto;
    font-size: 11px;
    align-items: center;
    display: flex;
    padding: 5px 0;
}

.footerinfo i {
    padding-right: 5px;
}

.footerinfo a {
    margin: 0;
    text-align: left;
    color: white;
    display:block;
    
}

.footerinfo p {
    margin:0;
}

.FooterSpacing {
    padding-top: 130px;
}

@media only screen and (min-width: 800px) {
    .footerinfo {
        width: fit-content;
        margin: 0 10px;
    }

    footer {
        display: flex;
        justify-content: center;
    }

    .FooterSpacing {
        padding-top: 40px;
    }
}