#galleryMain {
    padding: 0 15px;
}

.desktopView {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
}

#gallery {
    display: flex;
    flex-wrap: wrap;
    width:100%;
    max-width: 600px;
    padding-bottom: 20px;
    margin: 0 auto;
    align-self: stretch;
    overflow-y: scroll;
    margin-bottom: 10px;
    max-height: 40vh;
    
}

#galleryOverlay {
    flex-wrap: wrap;
    width:100%;
    padding-bottom: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    display: none;
    align-items: flex-start;
}

#galleryOverlay img {
    width: 32%;
    height: auto;
    margin: 0.5%;
    cursor: pointer;
}

#galleryOverlay img:hover {
    opacity: 0.8;
}

#overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
    display: none;
}

#overlay div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#overlay img {
    width: 80%;
    height: auto;
    max-width: 1000px;
    max-height: 90vh;
}

#overlay a {
    position: absolute;
    top: 0;
    color: white;
    cursor: pointer;
}

#gallery img {
    width: 49%;
    height: auto;
    margin: 0.5%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s;
}

#gallery img:hover{
    opacity: 1;
}

.mainImage {
    height: auto;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 10px;
    max-height: 40vh;
}

#overlay img:hover {
    opacity: 1 !important;
}

.alignLeft {
    text-align: left;
}

@media only screen and (min-width: 1000px) {
    #galleryMain {
        max-width: 1200px;
        margin: 0 auto;
    }

    .mainImage {
        display: none;
    }

    #gallery {
        display:none;
    }

    #galleryOverlay {
        display: flex;
    }

    .desktopView {
        flex-wrap: nowrap;
    }
}