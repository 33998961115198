#home h1, #home h2, #home p{
    text-align: left;
    padding-left: 10px;

}

#home h1 {
    position: relative;
    z-index: 2;
}

#home h2 {
    font-size: 18px;
    color: #2d5e90;
}

#home p {
    padding: 0 20px;
}

#display {
    position: relative;
}

#display h2{
    position: absolute;
    bottom: 10px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    text-transform: uppercase;
    border-radius: 0 5px 5px 0;
}

#display p {
    text-align: left;
}

#display .booking-button-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 30px;
    font-size: 16px;
    width: inherit;
    border-radius: 5px;
    transition: 0.3s;
}

#mainLinks-desktop {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    display:none;
    width: 100%;
}

#display .booking-button-mobile:hover {
    transform: translate(-50%, -50%) scale(1.05);
    background-color: white;
    color: #2d5e90;
}

.mainLinks {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Source Sans Pro', sans-serif;
    
} 

.linkHolder {
    width: 150px;
    position: relative;
    display: flex;
    flex-wrap: wrap;

}

.linkItem {
    width: 45%;
    position: relative;
    margin: 5px auto;
    min-width: 100px;
}

.linkItem img {
    width: 100%;
    height: auto;
}

.linkItem .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2c2c2c;
    font-size: 18px;
    transition: 0.25s;
    font-weight: 700;
}

.linkItem .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(242, 242, 242,0.6);
    transition: 0.5s;
}

.linkItem:hover .overlay {
    background-color: #f2f2f2;
}

.p-intro {
    margin-top: 10px;
}

.linkItem .blue {
    background-color: rgba(135,206,250,0.6);
}

.linkItem .yellow {
    background-color: rgba(237, 223, 56,0.6);
}

.linkItem .green {
    background-color: rgba(144,238,144,0.6)
}

.linkItem .red {
    background-color: rgba(231, 29, 54,0.6);
}

@media only screen and (min-width: 850px) {

    #display {
        height: calc(100vh - 106px);
        width:auto;
        overflow:hidden;
    }

    #mainLinks-desktop {
        display: block;
    }

    .mainLinks {
        justify-content: space-between;
    }

    #mainLinks-desktop .booking-button {
        display:block;
    }

    #mainLinks-mobile {
        display:none;
    }

    #display .booking-button-mobile {
        display:none;
    }

    .linkItem {
        max-width: 150px;
        min-width: 150px;
    }

    .linkItem .overlay {
        background-color: #f2f2f2;
    }
    
    .linkItem:hover .overlay {
        background-color: rgba(242, 242, 242,0.6);
    }

    .linkItem .blue {
        background-color: lightskyblue;
    }

    .blue:before {
        background-color: lightskyblue !important;
    }

    .linkItem:hover .blue {
       background-color: rgba(135,206,250,0.6);
    }

    .linkItem .green {
        background-color: lightgreen;
    }

    .green:after {
        background-color: lightgreen !important;
    }

    .linkItem:hover .green {
        background-color: rgba(144,238,144,0.6)
     }

    .linkItem .yellow {
        background-color:  #eddf38 ;
    }

    .yellow:before {
        background-color: #eddf38 !important;
    }

    .linkItem:hover .yellow{
        background-color: rgba(237, 223, 56,0.6);
     }

    .linkItem .red {
        background-color: #e71d36;
    }

    .red:after {
        background-color: #e71d36 !important;
    }

    .linkItem:hover .red {
        background-color: rgba(231, 29, 54,0.6);
     }

    .p-intro {
        display:none !important;
    }

    .margin-left {
        left: 15px;
    }
    
    .margin-left:before {
        background-color: #f2f2f2;
        content: "";
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        margin-left: -50%;
    }
    
    .margin-right {
        right: 15px;
    }
    
    .margin-right:after {
        background-color: #f2f2f2;
        content: "";
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        margin-right: -50%;
    }
}

@media only screen and (min-width: 1685px) {
    
    .linkItem {
        max-width: 200px;
    }

    #mainLinks-desktop {
        max-width: none;
    }
}