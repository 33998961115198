#restaurants {
    text-align: left;
    padding: 0 10px;
}
#restaurantItem {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 5px;
    box-shadow: 0px 2px 0px #2d5e90;
    align-items: flex-start;
}

#restaurantItem p {
    padding: 0 10px;
    padding-bottom: 0;
}

#restaurantItem img {
    width: 100%;
    height: auto;
}

#restaurantMain {
    position: relative;
}

#restaurantMain h1 {
    position: absolute;
    bottom:0;
    width: 100%;
    margin-bottom: 0;
    padding-left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #2d5e90;
    font-size: 26px;
}

#restaurantDetails {
    width: 100%;
    padding: 0 10px;
    align-self: flex-end;
}

#restaurantDetails a {
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-decoration: none;
    color: #2d5e90;
    display:block;
}

#restaurantDetails i {
    font-size: 12px;
}

#restaurantMain {
    position: relative;
}

@media only screen and (min-width: 800px) {
    #restaurants {
        max-width: 850px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }

    #restaurants h1{
        width:100%;
    }

    #restaurantItem {
        width: 48%;
        margin: 0 1%;
        margin-bottom: 15px;
    }
}