#about {
    text-align: left;
    padding: 0 15px;
}

#about img {
    width: 100%;
    height: auto;
}

#about p {
    padding-top: 10px;
}

@media only screen and (min-width: 800px) {
    #about {
        max-width: 850px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse
    }

    #about h1 {
        width: 100%;
    }
    #about img {
        padding-top: 10px;
        width: 50%;
        height: 50%;
    }

    #about p {
        padding-right: 10px;
        width: 50%;
    }
}