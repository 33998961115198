@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,700);

/*CSS for Header*/

#header {
    background-color: rgba(255, 255, 255, 0.9);
    width:100%;
    color: #2d5e90;
    z-index: 1;
}

#header nav a {
    padding: 8px 16px;
    color: #fff;
    text-decoration: none;
    display:block;
    font-size: 18px;
    font-weight: 700;
}

#header nav a:hover {
    transition: 0.25s;
    background-color: #3977b7;
}

#header h1 {
    text-align: left;
    padding-left: 10px;
}

.logo {
    display:-webkit-flex;
    display:flex;
}

.mobilelogo {
    padding: 2px 0 2px 10px;
}

.mobile-overlay {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 2;
    left:0;
    top:0;
    background-color: rgba(45, 94, 144, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
    
}

#mobile-nav {
    position: relative;
    top: 30px;
    width: 100%;
    text-align: left;
    margin-top: 30px;
    font-family: 'Source Sans Pro', sans-serif;
}

#mobile-nav #contact-links{
    margin-top: 20px;
}

#mobile-nav #contact-links a:hover {
    background-color: transparent;
}

.closeMenu {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    transition: 0.25s;
}

.closeMenu:hover {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    background: none !important;
}

.fixedMenu {
    position: fixed;
    right: 0;
    top: 0;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    padding: 2px;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 2;
    border-radius: 2px;
}

/* Desktop Nav */
#desktopNav {
    display: none;
}

@media only screen and (min-width: 1200px) {
    #desktopNav {
        display: -webkit-flex;
        display: flex;
        width: 100%;
        text-align: right;
        padding: 3px 10px;
        z-index: 2;
        -webkit-align-items: center;
                align-items: center;
    }

    #desktopNav .booking-button{
        width: 150px;
        display: inline-block;
    }

    #header {
        position: -webkit-sticky;
        position: sticky;
        top:0;
        background-color: rgba(255, 255, 255, 0.9);
    }

    #desktopNav a {
        display:inline;
        text-transform: uppercase;
        padding: 10px 15px;
        text-decoration: none;
        color: #2d5e90;
    }

    #desktopNav a:hover {
        color: #3977b7;
    }

    .mobileh1, .mobilelogo {
        display:none;
    }

    .mobile-overlay {
        display: none;
    }

    .fixedMenu {
        display: none;
    }
}
#carouselHome {
    max-height: 100%;
    height: 100%;
}

.carousel-inner {
    height:100%;
}

.carousel-item {
    height:100%;
}

.bg {
    height:100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

/*heading for carousel item */
.carousel-item p {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    color: #2d5e90;
    padding: 5px 10px;
    display: inline-block;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    font-weight: bold;
    border-bottom-right-radius: 5px;
}
#home h1, #home h2, #home p{
    text-align: left;
    padding-left: 10px;

}

#home h1 {
    position: relative;
    z-index: 2;
}

#home h2 {
    font-size: 18px;
    color: #2d5e90;
}

#home p {
    padding: 0 20px;
}

#display {
    position: relative;
}

#display h2{
    position: absolute;
    bottom: 10px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    text-transform: uppercase;
    border-radius: 0 5px 5px 0;
}

#display p {
    text-align: left;
}

#display .booking-button-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 10px 30px;
    font-size: 16px;
    width: inherit;
    border-radius: 5px;
    transition: 0.3s;
}

#mainLinks-desktop {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    display:none;
    width: 100%;
}

#display .booking-button-mobile:hover {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
            transform: translate(-50%, -50%) scale(1.05);
    background-color: white;
    color: #2d5e90;
}

.mainLinks {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-family: 'Source Sans Pro', sans-serif;
    
} 

.linkHolder {
    width: 150px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

}

.linkItem {
    width: 45%;
    position: relative;
    margin: 5px auto;
    min-width: 100px;
}

.linkItem img {
    width: 100%;
    height: auto;
}

.linkItem .content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #2c2c2c;
    font-size: 18px;
    transition: 0.25s;
    font-weight: 700;
}

.linkItem .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(242, 242, 242,0.6);
    transition: 0.5s;
}

.linkItem:hover .overlay {
    background-color: #f2f2f2;
}

.p-intro {
    margin-top: 10px;
}

.linkItem .blue {
    background-color: rgba(135,206,250,0.6);
}

.linkItem .yellow {
    background-color: rgba(237, 223, 56,0.6);
}

.linkItem .green {
    background-color: rgba(144,238,144,0.6)
}

.linkItem .red {
    background-color: rgba(231, 29, 54,0.6);
}

@media only screen and (min-width: 850px) {

    #display {
        height: calc(100vh - 106px);
        width:auto;
        overflow:hidden;
    }

    #mainLinks-desktop {
        display: block;
    }

    .mainLinks {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    #mainLinks-desktop .booking-button {
        display:block;
    }

    #mainLinks-mobile {
        display:none;
    }

    #display .booking-button-mobile {
        display:none;
    }

    .linkItem {
        max-width: 150px;
        min-width: 150px;
    }

    .linkItem .overlay {
        background-color: #f2f2f2;
    }
    
    .linkItem:hover .overlay {
        background-color: rgba(242, 242, 242,0.6);
    }

    .linkItem .blue {
        background-color: lightskyblue;
    }

    .blue:before {
        background-color: lightskyblue !important;
    }

    .linkItem:hover .blue {
       background-color: rgba(135,206,250,0.6);
    }

    .linkItem .green {
        background-color: lightgreen;
    }

    .green:after {
        background-color: lightgreen !important;
    }

    .linkItem:hover .green {
        background-color: rgba(144,238,144,0.6)
     }

    .linkItem .yellow {
        background-color:  #eddf38 ;
    }

    .yellow:before {
        background-color: #eddf38 !important;
    }

    .linkItem:hover .yellow{
        background-color: rgba(237, 223, 56,0.6);
     }

    .linkItem .red {
        background-color: #e71d36;
    }

    .red:after {
        background-color: #e71d36 !important;
    }

    .linkItem:hover .red {
        background-color: rgba(231, 29, 54,0.6);
     }

    .p-intro {
        display:none !important;
    }

    .margin-left {
        left: 15px;
    }
    
    .margin-left:before {
        background-color: #f2f2f2;
        content: "";
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        margin-left: -50%;
    }
    
    .margin-right {
        right: 15px;
    }
    
    .margin-right:after {
        background-color: #f2f2f2;
        content: "";
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        margin-right: -50%;
    }
}

@media only screen and (min-width: 1685px) {
    
    .linkItem {
        max-width: 200px;
    }

    #mainLinks-desktop {
        max-width: none;
    }
}
#about {
    text-align: left;
    padding: 0 15px;
}

#about img {
    width: 100%;
    height: auto;
}

#about p {
    padding-top: 10px;
}

@media only screen and (min-width: 800px) {
    #about {
        max-width: 850px;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse
    }

    #about h1 {
        width: 100%;
    }
    #about img {
        padding-top: 10px;
        width: 50%;
        height: 50%;
    }

    #about p {
        padding-right: 10px;
        width: 50%;
    }
}
#restaurants {
    text-align: left;
    padding: 0 10px;
}
#restaurantItem {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: left;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 5px;
    box-shadow: 0px 2px 0px #2d5e90;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

#restaurantItem p {
    padding: 0 10px;
    padding-bottom: 0;
}

#restaurantItem img {
    width: 100%;
    height: auto;
}

#restaurantMain {
    position: relative;
}

#restaurantMain h1 {
    position: absolute;
    bottom:0;
    width: 100%;
    margin-bottom: 0;
    padding-left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #2d5e90;
    font-size: 26px;
}

#restaurantDetails {
    width: 100%;
    padding: 0 10px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
}

#restaurantDetails a {
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-decoration: none;
    color: #2d5e90;
    display:block;
}

#restaurantDetails i {
    font-size: 12px;
}

#restaurantMain {
    position: relative;
}

@media only screen and (min-width: 800px) {
    #restaurants {
        max-width: 850px;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    #restaurants h1{
        width:100%;
    }

    #restaurantItem {
        width: 48%;
        margin: 0 1%;
        margin-bottom: 15px;
    }
}
#room{
    width: 90%;
    margin: 0 auto;
    text-align: left;
}

#room ul{
    padding-left: 20px;
    font-size: 14px;
    list-style-type: square;
}

#roomType {
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
    position: relative;
    cursor: pointer;
}

#roomType h2 {
    position: absolute;
    top: 0;
    width:100%;
    text-align: left;
    padding: 10px 0 10px 10px;
    background-color: #eaeef3;
    font-size: 17px;
    color: #2d5e90;
}

#roomType i {
    position: absolute;
    top: 0;
    right: 0;
    display:block;
    padding: 7.5px 5px 5px 0;
    color: #2d5e90;
}

#roomType img{
    width: 100%;
    height: auto;
}

#roomType .booking-button{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 32px;
    display:block;
    text-decoration: none;
    border-radius: 5px 0 0 0;
    line-height: 100%;
    width: auto;
    width: initial;
    margin: 0;
}

#roomType .booking-button p{
    margin: 0;
    padding: 0;
    color: white;
    font-size: 14px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    line-height: 100%;
}

#roomType .room-book:hover {
    background-color: #3c5976;
    text-decoration: none;
    color: white;
}

/* #roomType #overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */

/* Modal */
.room-modal {
  display: none; 
  position: fixed;
  z-index: 1; 
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); 
}

.room-modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  margin-top: 35px;
  padding: 0;
  width: 95%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.room-modal-content .flex{
    display:-webkit-flex;
    display:flex;
    text-align: left;
    -webkit-justify-content: center;
            justify-content: center;
}

.room-modal-content h1, .room-modal-content h2, .room-modal-content p {
    padding-left: 10px;
}

.room-modal-content h1{
    color: #2d5e90;
}
.room-modal-content h2{
    font-size: 18px;
}

.room-modal-content p {
    font-size: 14px;
}

.modal-book-button {
    width: 50%;
    background-color: #2d5e90;
    padding: 10px 32px;
    margin: 0 auto;
    margin-bottom: 10px;
    display:block;
    text-align: center;
}

.modal-book-button:hover {
    background-color: #3c5976;
    text-decoration: none;
    color: white;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}
  
@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
  
.room-modal-content .closeModal {
    color: #3c5976;
    font-size: 40px;
    font-weight: bold;
    float:right;
    padding: 0 10px;
    cursor: pointer;
}
  
.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (min-width: 800px) {
    #room {
        width: 80%;
        max-width: 850px;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    #room h1, #room p {
        width: 100%;
    }

    #roomType {
        width: 48%;
        margin: 0 1%;
        margin-bottom: 10px;
    }

    .extraInfo {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        margin: 10px 0;
        padding: 10px 0;
        border-top: 2px solid #2d5e90;
        border-bottom: 2px solid #2d5e90;
    }
    .infoList {
        width: 50%;
    }

    .room-modal-content {
        width: 80%;
        max-width: 400px;
        max-height: 90vh;
        overflow: auto;
        position: absolute;
        top:50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
}



#galleryMain {
    padding: 0 15px;
}

.desktopView {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: 1200px;
}

#gallery {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width:100%;
    max-width: 600px;
    padding-bottom: 20px;
    margin: 0 auto;
    -webkit-align-self: stretch;
            align-self: stretch;
    overflow-y: scroll;
    margin-bottom: 10px;
    max-height: 40vh;
    
}

#galleryOverlay {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width:100%;
    padding-bottom: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    display: none;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

#galleryOverlay img {
    width: 32%;
    height: auto;
    margin: 0.5%;
    cursor: pointer;
}

#galleryOverlay img:hover {
    opacity: 0.8;
}

#overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
    display: none;
}

#overlay div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

#overlay img {
    width: 80%;
    height: auto;
    max-width: 1000px;
    max-height: 90vh;
}

#overlay a {
    position: absolute;
    top: 0;
    color: white;
    cursor: pointer;
}

#gallery img {
    width: 49%;
    height: auto;
    margin: 0.5%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s;
}

#gallery img:hover{
    opacity: 1;
}

.mainImage {
    height: auto;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 10px;
    max-height: 40vh;
}

#overlay img:hover {
    opacity: 1 !important;
}

.alignLeft {
    text-align: left;
}

@media only screen and (min-width: 1000px) {
    #galleryMain {
        max-width: 1200px;
        margin: 0 auto;
    }

    .mainImage {
        display: none;
    }

    #gallery {
        display:none;
    }

    #galleryOverlay {
        display: -webkit-flex;
        display: flex;
    }

    .desktopView {
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
}
#contact {
    padding: 0 15px;
    text-align: left;
}

#contact h2 {
    padding-top: 10px;
    font-size: 20px;
    color: #2d5e90;
    margin: 0;
}

#contactInfo {
    padding: 10px;
    background-color:#2d5e90;
}

#contact img{
    width:100%;
    height:auto;
}

#contactInfo, #contactInfo i{
    font-size: 14px;
}

#contactInfo a {
    margin: 0;
    padding: 0;
    padding-left: 5px;
    display: block;
    text-decoration: none;
    color: inherit;
    color: white;
}

#contactInfo a:hover {
    text-decoration: underline;
}

#contactInfo img {
    width: 100%;
    height: auto;
}

form {
    margin: 0 0 2em 0;
    overflow-x: hidden;
}

    form > :last-child {
        margin-bottom: 0;
    }

    form > .fields {
        display: -moz-flex;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        width: calc(100% + 3em);
        margin: -1.5em 0 10px -1.5em;
    }

        form > .fields > .field {
            -moz-flex-grow: 0;
            -webkit-flex-grow: 0;
            -ms-flex-grow: 0;
            flex-grow: 0;
            -ms-flex-shrink: 0;
            -webkit-flex-shrink: 0;
                    flex-shrink: 0;
            padding: 1.5em 0 0 1.5em;
            width: calc(100% - 1.5em);
        }

            form > .fields > .field.half {
                width: calc(50% - 0.75em);
            }

            form > .fields > .field.third {
                width: calc(100%/3 - 0.5em);
            }

            form > .fields > .field.quarter {
                width: calc(25% - 0.375em);
            }

    @media screen and (max-width: 480px) {

        form > .fields {
            width: calc(100% + 3em);
        }

            form > .fields > .field {
                padding: 1.5em 0 0 1.5em;
                width: calc(100% - 1.5em);
            }

                form > .fields > .field.half {
                    width: calc(100% - 1.5em);
                }

                form > .fields > .field.third {
                    width: calc(100% - 1.5em);
                }

                form > .fields > .field.quarter {
                    width: calc(100% - 1.5em);
                }

    }


input[type="text"],
input[type="email"],
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #c9c9c9;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0;
    text-decoration: none;
    width: 100%;
}

    input[type="text"]:invalid,
    input[type="email"]:invalid,
    textarea:invalid {
        box-shadow: none;
    }

    input[type="text"]:focus,
    input[type="email"]:focus,
    textarea:focus {
        border-bottom-color: #c0cedd;
        box-shadow: inset 0 -1px 0 0 #c0cedd;
    }

input[type="text"],
input[type="password"],
input[type="email"],
select {
    height: 3em;
}

textarea {
    padding: 0;
    min-height: 3.75em;
}


#contactForm {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 10px;
}

#contactForm button {
  background-color: #2d5e90;
  border: none;
  color: white;
  padding: 15px 32px;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;  
  cursor: pointer;
  width: 50%;
  margin: 0 auto;
  display:block;
  border-radius: 5px;
}

#contactForm button:hover {
    background-color: #3977b7;
}

.hidden-img {
    display:none;
}

@media only screen and (min-width: 600px) {
    #contact {
        max-width: 850px;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #contact h1{
        width:100%;
    }

    #contact img {
        width: 50%;
        height: 50%;
    }

    #contactInfo {
        width:50%;
        display:-webkit-flex;
        display:flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-align-items: center;
                align-items: center;
    }

    #contactInfo a {
        width: 100%;
    }

    #contactForm{
        width: 50%;
        padding: 10px 10px 10px 0;
        
    }

    .hidden-img {
        display:block;
    }
}

footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: #2d5e90;
    color: #fff;
    width:100%;
    bottom: 0;
    left: 0;
    position: absolute;
    margin-top: 45px;
}

.footerinfo {
    width: 80%;
    margin: 0 auto;
    font-size: 11px;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    padding: 5px 0;
}

.footerinfo i {
    padding-right: 5px;
}

.footerinfo a {
    margin: 0;
    text-align: left;
    color: white;
    display:block;
    
}

.footerinfo p {
    margin:0;
}

.FooterSpacing {
    padding-top: 130px;
}

@media only screen and (min-width: 800px) {
    .footerinfo {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 10px;
    }

    footer {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .FooterSpacing {
        padding-top: 40px;
    }
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #262626;
  font-weight: 700;
}

h3 {
  font-weight: 300;
}
p {
  color: #262626;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.booking-button {
  width: 50%;
  max-width: 300px;
  background-color: #2d5e90;
  color: white;
  padding: 10px 32px;
  margin: 0 auto;
  margin-bottom: 10px;
  display:block;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 700;
}

.booking-button:hover {
  background-color: #3977b7;
  text-decoration: none;
  color: white;
}

.underline {
  position: relative;
  font-weight: 300;
}

.underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #2d5e90;
}


