/*CSS for Header*/

#header {
    background-color: rgba(255, 255, 255, 0.9);
    width:100%;
    color: #2d5e90;
    z-index: 1;
}

#header nav a {
    padding: 8px 16px;
    color: #fff;
    text-decoration: none;
    display:block;
    font-size: 18px;
    font-weight: 700;
}

#header nav a:hover {
    transition: 0.25s;
    background-color: #3977b7;
}

#header h1 {
    text-align: left;
    padding-left: 10px;
}

.logo {
    display:flex;
}

.mobilelogo {
    padding: 2px 0 2px 10px;
}

.mobile-overlay {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 2;
    left:0;
    top:0;
    background-color: rgba(45, 94, 144, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
    
}

#mobile-nav {
    position: relative;
    top: 30px;
    width: 100%;
    text-align: left;
    margin-top: 30px;
    font-family: 'Source Sans Pro', sans-serif;
}

#mobile-nav #contact-links{
    margin-top: 20px;
}

#mobile-nav #contact-links a:hover {
    background-color: transparent;
}

.closeMenu {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    transition: 0.25s;
}

.closeMenu:hover {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    background: none !important;
}

.fixedMenu {
    position: fixed;
    right: 0;
    top: 0;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    padding: 2px;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 2;
    border-radius: 2px;
}

/* Desktop Nav */
#desktopNav {
    display: none;
}

@media only screen and (min-width: 1200px) {
    #desktopNav {
        display: flex;
        width: 100%;
        text-align: right;
        padding: 3px 10px;
        z-index: 2;
        align-items: center;
    }

    #desktopNav .booking-button{
        width: 150px;
        display: inline-block;
    }

    #header {
        position: sticky;
        top:0;
        background-color: rgba(255, 255, 255, 0.9);
    }

    #desktopNav a {
        display:inline;
        text-transform: uppercase;
        padding: 10px 15px;
        text-decoration: none;
        color: #2d5e90;
    }

    #desktopNav a:hover {
        color: #3977b7;
    }

    .mobileh1, .mobilelogo {
        display:none;
    }

    .mobile-overlay {
        display: none;
    }

    .fixedMenu {
        display: none;
    }
}